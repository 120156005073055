<template>
  <CContainer class="text-black" style="padding-bottom: 100px">
    <nav-bar
      v-bind:headerName="headerName"
      v-bind:cartTotal="cartTotal"
    ></nav-bar>
    <hr class="mt-0" />
    <div class="card">
      <div
        v-if="
          data.remoteImagePath === null || data.remoteImagePath === undefined
        "
        class="square-box"
        :style="{
          'background-color': getImgColor(data.indexColor),
          'border-radius': '10px',
          width: '100%',
        }"
      ></div>
      <img
        v-else
        style="border-radius: 10px"
        alt=""
        :src="data.remoteImagePath"
        class="img-fluid"
        onerror="this.onerror=null;this.src='https://www.img.in.th/images/d6fa08b8f195f3808f7f3bbcb8e43c9f.png';"
      />
    </div>

    <table style="width: 100%" aria-describedby="">
      <th scope="col"></th>
      <tr>
        <td style="vertical-align: bottom" class="h2 font-weight-bold">
          {{ data.SKUName }}
        </td>
        <td
          style="text-align: center; vertical-align: middle; width: 30%"
          class="text-right text-grey"
        >
          {{ data.SKUPrice }} THB
        </td>
      </tr>
      <tr>
        <td style="vertical-align: bottom" class="p text-description">
          <span v-if="PLULists.length === 1">
            {{ SKURatio }} {{ unitName }}
          </span>
          <span style="font-size: 12px" v-else>ระบุจำนวนสินค้า</span>
        </td>
        <td class="text-right">
          <h6
            class="mb-1 font-weight-bold"
            :style="{ color: getCategoryColor }"
          >
            <em class="fas fa-layer-group"></em> {{ categoryName }}
          </h6>
        </td>
      </tr>
    </table>

    <hr />

    <ul class="list-group" v-if="PLULists.length > 1">
      <li class="list-group-item" v-for="plu in PLULists" :key="plu.objectId">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            v-model="ProductPLU"
            :value="plu"
          />
          <label class="form-check-label d-flex justify-content-between">
            <span> {{ plu.SKURatio }} {{ unitName }} </span>
            <span class="text-description">
              {{ PLUPrice(plu.price) }}
            </span>
          </label>
        </div>
      </li>
    </ul>

    <br />

    <table style="width: 100%; margin: auto" aria-describedby="">
      <th scope="col"></th>
      <tr>
        <td style="width: 65%">
          <h6><CIcon name="cil-basket"></CIcon> จำนวน</h6>
        </td>
        <td style="width: 35px">
          <button
            v-if="quantity > 0"
            class="btn btn-outline-light text-success btn-block btn-sm"
            style="border: 1px solid #e5e5e5"
            @click="updateQuantity(-1)"
          >
            <strong>-</strong>
          </button>

          <button
            v-else-if="quantity <= 0"
            class="btn btn-outline-light text-success btn-block btn-sm"
            style="border: 1px solid #e5e5e5"
            disabled
          >
            <strong>-</strong>
          </button>
        </td>
        <td style="width: 15%">
          <input
            class="form-control text-center font-weight-bold"
            style="border: none"
            v-model.number="quantity"
            type="number"
            min="0"
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </td>
        <td style="width: 35px">
          <button
            class="btn btn-outline-light text-success btn-block btn-sm"
            style="border: 1px solid #e5e5e5"
            @click="updateQuantity(1)"
          >
            <strong>+</strong>
          </button>
        </td>
      </tr>
    </table>

    <hr />
    <!-- <div class="d-flex justify-content-between">
      <h6 class="mb-1"><CIcon name="cil-money"></CIcon> ราคา</h6>
      <h6 class="text-grey">{{ summary }} บาท</h6>
    </div> -->

    <br />
    <p class="text-black">
      <strong>Note to shop </strong>
      <span class="text-description" style="font-size: 9px">optional</span>
    </p>
    <input
      v-model="note"
      class="pl-0 form-control form-noborder"
      placeholder="Add your request"
    />

    <footer class="fixed-bottom">
      <div v-if="ProductPLU !== ''">
        <button
          class="btn btn-success btn-block"
          v-if="quantity > 0"
          @click="addToCart()"
        >
          <em class="fas fa-cart-plus"></em> เพิ่มไปยังรถเข็น - {{ summary }}
        </button>
        <button class="btn btn-success btn-block" v-else @click="goback()">
          <em class="fas fa-home"></em> กลับเมนูหลัก
        </button>
      </div>
      <div v-else>
        <button class="btn btn-success btn-block" disabled>
          <em class="fas fa-cart-plus"></em> เพิ่มไปยังรถเข็น
        </button>
      </div>
    </footer>
  </CContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/pos'
import util from '@/util/util'
import order from '@/util/order'
import NavBar from '../menu/NavBar'

export default {
  components: {
    NavBar,
  },
  data() {
    return {
      products: [],
      data: {},
      quantity: 1,
      note: '',
      cartLists: [],
      PLULists: [],
      ProductPLU: '',
      headerName: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    objectId() {
      return this.$route.params.objectId
    },
    SKURatio() {
      if (this.data.productPLU) {
        return this.data.productPLU.SKURatio || '1'
      } else {
        return '1'
      }
    },
    unitName() {
      if (this.data.unit) {
        return this.data.unit.name
      } else {
        return ''
      }
    },
    categoryName() {
      if (this.data.category) {
        return this.data.category.name
      } else {
        return ''
      }
    },
    getCategoryColor() {
      if (this.data.indexColor !== undefined) {
        return util.generateColor(this.data.indexColor)
      }
    },
    summary() {
      let sum = 0
      if (this.ProductPLU !== '') {
        if (this.ProductPLU.price !== undefined) {
          sum = this.ProductPLU.price * this.quantity
        } else {
          sum = this.ProductPLU.SKURatio * this.data.SKUPrice * this.quantity
        }
      }
      return util.convertCurrency(sum)
    },
    cartTotal() {
      let total = this.cartLists.reduce((accum, product) => {
        return accum + product.quantity
      }, 0)
      return total
    },
  },
  created() {
    this.getProduct()
    this.getPLUList()
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
  },
  methods: {
    ...util,
    PLUPrice(price) {
      console.log(price)
      if (price === null || price === undefined) {
        return ''
      } else {
        return util.convertNumber(price) + ' THB'
      }
    },
    getImgColor(indexColor) {
      return util.generateColor(indexColor)
    },
    goback() {
      window.history.back()
    },
    updateQuantity(value) {
      this.quantity = this.quantity + value

      if (this.quantity === 0) {
        this.deleteFromCartList()
      }
    },
    getQuantity() {
      let item = this.makeReceiptItem()

      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === item.productPLUId
      })

      if (locationInCart === -1) {
        this.quantity = 1
      } else {
        this.quantity = this.cartLists[locationInCart].quantity
      }
    },
    getProduct() {
      const shopObjectId = this.shopLineObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }
      const headers = {
        shopObjectId: shopObjectId,
      }

      console.log(this.objectId)
      pos({
        url: '/api/v1.0/' + this.uid + '/ProductSKU/data/' + this.objectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data.document
        console.log(res)
      })
    },
    getPLUList() {
      let params = {
        skuObjectId: this.objectId,
        includePLU1: true,
      }
      const headers = {
        shopObjectId: this.shopLineObjectId,
      }

      pos({
        url: '/api/v1.0/' + this.uid + '/productplu/getbysku',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.PLULists = res.data.data
        console.log(res.data.data)
        if (this.PLULists.length === 1) {
          this.ProductPLU = this.PLULists[0]
        }
        this.getQuantity()
      })
    },
    makeReceiptItem() {
      let SKUData = this.data
      let PLUData = this.ProductPLU

      return order.createReceiptItemsDefault(
        SKUData,
        PLUData,
        this.quantity,
        this.note
      )
    },
    addToCart() {
      let item = this.makeReceiptItem()

      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === item.productPLUId
      })

      if (locationInCart === -1) {
        this.cartLists.push(item)
      } else {
        this.cartLists[locationInCart] = item
      }

      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
      window.history.back()
    },
    deleteFromCartList() {
      let item = this.makeReceiptItem()

      const locationInCart = this.cartLists.findIndex((p) => {
        return p.productPLUId === item.productPLUId
      })

      this.cartLists.splice(locationInCart, 1)
      sessionStorage.setItem('cart', JSON.stringify(this.cartLists))
    },
  },
}
</script>

<style scoped>
.form-noborder {
  border: none;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px;
}
input::placeholder {
  font-size: 10px;
}
footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
  padding: 30px;
}
</style>
